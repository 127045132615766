.content {
    display: flex;
    width: 100%;
    max-width: calc(1215px * var(--scale));
    flex-direction: column;
    margin: auto;
    padding: 16px;
    padding-top: 50px;
    @media screen and (min-width: 1081px) {
        padding: 0 calc(80px * var(--scale));
        padding-top: calc(155px * var(--scale));
        flex-direction: row;
    }
    &.skillLeaderboard {
        max-width: calc(1758px * var(--scale));

        .list {
            width: 100%;
            transition: width 0.5s ease;

            &.hasCrest {
                width: 100%;
                @media screen and (min-width: 1081px) {
                    width: calc(100% - calc(543px * var(--scale)));
                }
              
            }
        }

        // .crest {
        //     width: 100%;
        //     padding-left: 0;
        //     @media screen and (min-width: 768px) {
            
        //         width: calc(543px * var(--scale));
        //         padding-left: calc(80px * var(--scale));
        //     }
          
        // }
    }
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.item {
    display: flex;
    min-height: calc(90px * var(--scale));
    align-items: center;
    margin-bottom: calc(12px * var(--scale));
    background-color: var(--table-background);
    padding: 0 calc(12px * var(--scale));
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    @media screen and (min-width: 1081px) {
        padding: 0 calc(20px * var(--scale));
    }

    &.active {

        background: var(--table-text-accent);

        .position,
        .name,
        .total {
            color: var(--table-background);
        }


        .scoreItem {

            .scoreName,
            .scoreVal {
                color: var(--table-background);
            }

            .scoreName {
                opacity: 0.77;
            }
        }
    }
}

.left {
    display: flex;
    align-items: center;

    flex: 1 0 0;
    margin-right: calc(8px * var(--scale));
}

.right {
    display: flex;
    padding: 0px calc(12px * var(--scale));
    align-items: flex-start;
    margin-right: calc(8px * var(--scale));
}

.total {
    color: var(--table-text-accent);
    text-align: right;
    font-size: calc(48px * var(--scale));
    font-weight: 700;
    letter-spacing: calc(-0.56px * var(--scale));
    @media screen and (min-width: 768px) {
        font-size: calc(56px * var(--scale)); 
    }
}

.position {
    color: var(--table-text-accent);
    font-size: calc(32px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: calc(20px * var(--scale));
    @media screen and (min-width: 768px) {
        font-size: calc(62px * var(--scale));
    }
}

.flag {
    border-radius: calc(1px * var(--scale));
    border: calc(1.375px * var(--scale)) solid #FFF;
    margin-right: calc(8px * var(--scale));
    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
    }
}

.name {
    color: var(--table-text);
    font-size: calc(32px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: calc(38px * var(--scale));
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
        font-size: calc(44px * var(--scale));
        line-height: calc(56px * var(--scale));
    }
}

.scoreItem {
    display: flex;
    width: calc(36px * var(--scale));
    flex-direction: column;
    align-items: center;
    margin-right: calc(8px * var(--scale));
    @media screen and (min-width: 768px) {
        width: calc(40px * var(--scale));
        margin-right: calc(10px * var(--scale));
    }
    &.skill {
        flex-direction: row;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        min-width: calc(92px * var(--scale));
        width: auto;

        .scoreVal {
            color: var(--table-text);
            font-size: calc(32px * var(--scale));
            font-style: normal;
            font-weight: 700;
            line-height: calc(56px * var(--scale));
            text-transform: uppercase;
            margin-right: calc(8px * var(--scale));
            @media screen and (min-width: 768px) {
                font-size: calc(44px * var(--scale));
            }
        }

        .scoreName {
            color: var(--table-text);
            text-align: center;
            font-size: calc(32px * var(--scale));
            font-style: normal;
            font-weight: 600;
            line-height: calc(32px * var(--scale));
            text-transform: none;
            @media screen and (min-width: 768px) {
                font-size: calc(36px * var(--scale));
                line-height: calc(32px * var(--scale));
            }
        }
    }
}

.scoreName {
    color: var(--table-text);
    text-align: center;
    font-size: calc(18px * var(--scale));
    font-style: normal;
    font-weight: 600;
    line-height: calc(26px * var(--scale));
    /* 145.455% */
    text-transform: uppercase;
    opacity: 0.5;
    @media screen and (min-width: 768px) {
        font-size: calc(22px * var(--scale));
        line-height: calc(32px * var(--scale));
    }
}

.scoreVal {
    color: var(--table-text);
    text-align: center;
    font-size: calc(28px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: calc(40px * var(--scale));
    @media screen and (min-width: 768px) {
        font-size: calc(32px * var(--scale));
    }
}